import { Box, Text } from "@chakra-ui/react";

const HomeView = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" h="100vh">
      <Text color="white" fontWeight="semibold" fontSize={"8xl"}>
        Kenatzumu
      </Text>
    </Box>
  );
};

export default HomeView;
